import React, { useEffect } from "react";
import { type GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { Divider } from "@mantine/core";

import { useAuth } from "@/components/auth/AuthContext";
import Regions from "@/components/regions/Regions";
import AppleAppButtonIcon from "@/components/ui/AppleAppButtonIcon";
import GoogleAppButtonIcon from "@/components/ui/GoogleAppButtonIcon";
import LogoBlack from "@/components/ui/Logo";
import PreLoginPage from "@/components/wrappers/PreLoginPage";
import { getPageRoute } from "@/utils/constants/pageRoutes";
import { getStorageValue } from "@/utils/storage/storage";

import { type getStaticGeneratedProps } from "@/types";

const RegionsPage = ({ lang, region }: getStaticGeneratedProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { token, user } = useAuth();

  useEffect(() => {
    const preferredLang = getStorageValue("YO_LANG") ?? lang;

    const handleRedirects = async () => {
      if (token) {
        router.isReady &&
          router.push(
            `${getPageRoute(
              "gigsOpen",
              String(region),
              String(preferredLang),
            )}?targetPath=${router.asPath}`,
          );
      }
    };

    handleRedirects();
  }, [lang, region, router, token, user]);

  return (
    <PreLoginPage>
      <div className="mx-auto flex max-w-fit flex-col items-center gap-11 break-words text-center lg:h-full lg:gap-14">
        <LogoBlack width={110} />
        <Regions />
        <Divider className="w-full lg:hidden" />
        <div className="lg:hidden">
          <h2 className="w-full text-2xl font-bold leading-10 md:text-3xl">
            {t("login.freelancerQuestion")}
          </h2>
          <p className="mb-7 mt-4 w-full text-base leading-6">
            {t("login.downloadTheApp")}
          </p>
          <div className="flex flex-wrap justify-center gap-3">
            <a
              href={process.env.NEXT_PUBLIC_GOOGLE_PLAY_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="cursor-pointer"
            >
              <GoogleAppButtonIcon height={65} />
            </a>
            <a
              href={process.env.NEXT_PUBLIC_APPLE_STORE_URL}
              rel="noreferrer noopener"
              target="_blank"
              className="cursor-pointer"
            >
              <AppleAppButtonIcon height={65} />
            </a>
          </div>
        </div>
      </div>
    </PreLoginPage>
  );
};

export default RegionsPage;

export const getStaticProps: GetStaticProps = async ({ params }) => ({
  props: {
    region: params?.region ?? "nl",
    lang: params?.lang ?? "nl-NL",
    ...(await serverSideTranslations(String(params?.lang) || "nl-NL")),
  },
});
